html,
body {
  height: 100%;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: white;
}
h1,
h2 {
  font-family: "Lato", sans-serif;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
  margin-top: 3rem;
}
a {
  text-decoration: none;
  font-size: 1.2rem;
  color: inherit;
}
#root,
main {
  height: 100%;
}
main {
  display: flex;
  flex-direction: column;
}
p,
.article li,
.article a {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #2a2a2b;
}
p {
  margin: 0.8rem 0 0.8rem;
}
p a {
  text-decoration: underline;
  color: #3966FF;
}
.article-image {
  text-align: center;
  margin: 30px 0;
}
.main {
  flex: 1;
  padding-top: 60px;
}
header {
  width: 100%;
  position: fixed;
  padding: 5px;
  height: 60px;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1;
}
header > div {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
header .zenflow-logo {
  width: 50px;
}
header > div > div:nth-child(2) {
  flex: 1;
}
.header-profile {
  position: relative;
}
.header-profile-name,
.header-profile-logout {
  font-weight: 500;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #2a2a2b;
  letter-spacing: 0.05rem;
}
.header-profile-logout {
  margin-left: 20px;
  cursor: pointer;
}
.header-profile-logout:hover {
  text-decoration: underline;
}
.header-profile-dropdown {
  display: none;
  position: absolute;
  top: 25px;
  right: 0;
  padding: 10px 0;
  background: white;
  border-radius: 5px;
  width: 150px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}
.header-profile-dropdown a {
  font-size: 0.9rem;
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  color: #2a2a2b;
}
.header-profile-dropdown a:hover {
  background: #fafafb;
}
.sidebar {
  width: 280px;
  padding-bottom: 60px;
  flex: 0;
  overflow: scroll;
  position: fixed;
  height: 100%;
}
.page {
  width: 1000px;
  margin: 0 auto;
  display: flex;
}
.article {
  padding: 0 0 50px 30px;
  flex: 1;
  padding-left: 280px;
}
.article img {
  max-width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}
.nav-article-group {
  margin-top: 20px;
}
.nav-article-group.hide .nav-article-group-children {
  display: none;
}
.nav-article-group-header {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 2rem 0 1rem;
  color: #2a2a2b;
  cursor: pointer;
}
.nav-article-group-item {
  font-size: 1rem;
  color: #2a2a2b;
  margin: 5px 0;
  padding: 5px 0;
  display: block;
}
.nav-article-group-item:not(.active):hover {
  text-decoration: underline;
}
.nav-article-group-item.active {
  font-weight: bold;
}
.nav-article-group-children {
  padding-left: 20px;
}
.login-form {
  width: 360px;
  margin: 100px auto 0;
}
.form-error {
  margin-top: 10px;
  background: #ffcaca;
  padding: 15px;
  color: #3a0000;
}
input[type="text"],
input[type="password"] {
  border: 1px solid #eaeaea;
  font-size: 1rem;
  padding: 10px;
  font-family: "Open Sans", sans-serif;
  width: 100%;
}
label {
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  opacity: 0.6;
}
.input {
  margin: 15px 0;
}
.input label {
  display: block;
  margin-bottom: 3px;
}
.button {
  padding: 15px 20px;
  background: #3966FF;
  color: white;
  text-align: center;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}
